import React from "react"
import Default from "../layouts/default"

const PageInfo = {
  desc:
    "Pension in zentraler Lage direkt am Markt mit modern eingerichteten Zimmern und Ferienwohnungen für 1 – 4 Personen",
  keywords:
    "übernachtung, pension, erzgebirge, impressum, zschopau, hotel, ferienwohnung, zur altdeutschen, einzelzimmer, doppelzimmer",
  title: 'Impressum – Pension "Zur Altdeutschen" Zschopau/Erzgebirge',
}

const Impressum = () => (
  <Default pageInfo={PageInfo} headerImg="/assets/img/174.jpg">
    <div>
      <h1>Impressum: Pension "Zur Altdeutschen"</h1>
      <h2>Geschäftsführer</h2>
      <address>
        Eva Schönstein
        <br />
        Neumarkt 1<br />
        09405 Zschopau
        <br />
        <br />
        Tel: (037 25) 227 34
        <br />
        mobil: (01 76) 12 62 60 43
      </address>

      <p>UID-Nr: 228 271 03474</p>
      <h2>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</h2>
      <p>
        Eva Schönstein
        <br />
        Anschrift: s. oben
        <br />
        Tel: (037 25) 227 34
        <br />
        e-Mail: zuraltdeutschen@aol.com
        <br />
        web: zur-altdeutschen.de
      </p>
      <h2>Copyright</h2>
      <p>Alle Rechte für Texte und Bilder liegen bei Eva Schönstein.</p>

      <h2>Haftungsausschluss</h2>
      <p>
        Diese Internetseite enthält Verknüpfungen zu Internetseiten Dritter.
        Diese unterliegen der Haftung der jeweiligen Betreiber. Zum Zeitpunkt
        der erstmaligen Verknüpfung waren keine Rechtsverstöße ersichtlich.
        <br />
        Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige
        Gestaltung der Inhalte dieser verknüpften Seiten. Bei Kenntnis von
        Rechtsverstößen werden jedoch derartige externe Links unverzüglich
        gelöscht.
      </p>

      <h2>Icons (The Noun Project)</h2>
      <p>
        Hairdryer By Georgiana Ionescu <br />
        Boiler By Komkrit Noenpoempisut, TH
        <br />
        Bed By Riand, ID
        <br />
        Pet, Coffee, Fridge, Four Room, Double Room, Cocktail, Cash, Car (used
        for Parking Icon), Washing Machine, TV, Triple Room, Stove, Single Room,
        Sign Post, Single Bed, Key, Room Card (repurposed for Credit Card Icon),
        Pin, PC, Phone By Baboon designs, US
      </p>

      <h2>Design und Umsetzung</h2>
      <p>Paul Mertin</p>
    </div>
  </Default>
)

export default Impressum
